import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"


const AboutPage = ({ data }) => {

  return (
    <Layout isOverflowHidden>
      <Seo title={data.wpPage.title} />
      <div className="container about-wrapper">
        <div className="about-container">
          <div className="about-image_container about-flex_1">
            <GatsbyImage
              image={
                data.wpPage.featuredImage.node.localFile.childImageSharp
                  .gatsbyImageData
              }
              imgStyle={{ height: "100%", width: "auto" }}
              alt={data.wpPage.title}
            />
          </div>
          <div className="about-content_container about-flex_1">
          {parse(data.wpPage.content)}
          </div>
          <div className="about-flex_1 hidden-1300"></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(title: { eq: "About" }) {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      id
      title
      content
    }
  }
`

export default AboutPage
